import { useState } from "react";

import { useAuth } from "@m/login";
import { toast } from "@m/ui";

import { useLazyQuery } from "@mc/utils/graphql";

export const useExportResources = ({
  query,
  variables,
  exportFilename,
}: {
  query;
  variables;
  exportFilename;
}) => {
  const { user } = useAuth();
  const [exportFailed, setExportFailed] = useState<boolean>(false);

  const [startExport, { loading, called }] = useLazyQuery(query, {
    variables,
    context: {
      exportFilename, // Passing exportFilename instead of filename will automatically add the correct header that kicks off the backend export job flow
    },
    fetchPolicy: "no-cache",
    onCompleted: () =>
      toast.success(
        `Your report is being generated and will be emailed to ${user.email} shortly.`,
        { autoClose: false }
      ),
    onError: () => {
      setExportFailed(true);
      toast.error("There was a problem. Please, try again later.", {
        autoClose: false,
      });
    },
  });

  return [startExport, { loading, called, exportFailed }] as const;
};
