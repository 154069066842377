import { PaperClipIcon } from "@heroicons/react/20/solid";
import { MutableRefObject, useState } from "react";

import { Activity, Conversation } from "@m/api/public/types";
import { useAuth } from "@m/login";
import { Button, Buttons, toast } from "@m/ui";
import { dt } from "@m/utils";

import { AttachmentPill } from "@mc/components/AttachmentPill";
import { ACTIVITY_TYPES } from "@mc/components/ReplyTimeline/constants";
import { useCreateCaseComment } from "@mc/features/SupportCases/api";

import { useValidCaseUploadMimeTypes } from "../api";
import { MESSAGES } from "../constants";
import { FileInputType, useFileInput } from "../hooks";
import { batchUploadAttachments } from "../utils";

import { ReplyBox } from "./ReplyBox";

// There is a limit of 10 attachments allow to be stored with a comment
const ATTACHMENT_LIMIT = 10;

const createLocalComment = (user, message, commentAttachments) => {
  const { name, firstName, lastName, email } = user;
  const datetime = dt.local();
  return {
    sysId: datetime.toMillis(),
    type: ACTIVITY_TYPES.COMMENT,
    comment: message,
    createdAt: datetime.toISO(),
    createdBy: {
      name,
      firstName,
      lastName,
      email,
    },
    commentAttachments,
  };
};

interface ReplyTimelineFormProps {
  detail: any;
  textBoxRef: MutableRefObject<any>;
  setActivities: (value: { node: Activity | Conversation }[]) => void;
  refetch: () => void;
}

export function ReplyTimelineForm({
  detail,
  setActivities,
  refetch,
  textBoxRef,
}: ReplyTimelineFormProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [replyMessage, setReplyMessage] = useState("");

  const { user } = useAuth();
  const fileInput = useFileInput();
  const {
    files,
    removeAllAttachments,
    fileInputRef,
    attachFile,
    commentAttachments,
  } = fileInput;

  const [createCaseComment] = useCreateCaseComment(detail, replyMessage);

  const { data: validMimeTypes, loading: validMimeTypesLoading } =
    useValidCaseUploadMimeTypes();

  const handleChange = ({ target }) => setReplyMessage(target.value);

  const handleSubmitReply = async (e) => {
    e.preventDefault();
    if (!replyMessage && files.size === 0) return;

    setIsLoading(true);
    let response;
    let shouldRefetch;
    let createdComment;
    let usingLocalComment = false;

    if (replyMessage) {
      try {
        response = await createCaseComment();
        shouldRefetch = true;
      } catch (exc) {
        toast.error(exc.message);
        // When comment fails, end early without uploading attachments
        setIsLoading(false);
        return;
      }

      createdComment = response?.data?.createCaseComment?.comment;

      if (!createdComment) {
        // If the GraphQL response doesn't *include* the comment, it hasn't
        // been persisted to the database yet (though it will be shortly via
        // ServiceNow webhooks). In this situation, just draw a comment so
        // the user gets visual feedback after filling out the form and knows
        // their comment was submitted.
        createdComment = createLocalComment(
          user,
          replyMessage,
          commentAttachments
        );
        usingLocalComment = true;
      }

      setActivities([{ node: createdComment }, ...detail.activities.edges]);
      setReplyMessage("");
    }

    if (files.size > 0) {
      // If we're using a locally-generated (temporary) comment, we can't
      // use its sysId to link attachments to the comment. In this situation,
      // send a null for the comment id and upload the attachment(s) as
      // standalone activities.
      const commentSysId = usingLocalComment ? null : createdComment?.sysId;
      const attachments = Array.from(files.values());
      const responses = await batchUploadAttachments(
        attachments,
        detail.sysId,
        commentSysId
      );

      const failed = responses.filter(
        (result): result is PromiseRejectedResult =>
          result.status === "rejected"
      );

      if (failed.length > 0) {
        failed.forEach((failure) => {
          const fileName = failure?.reason?.file?.name || "";
          const errorMessage = failure?.reason?.error?.message;
          const attachmentMessage = fileName
            ? `${MESSAGES.ATTACHMENT_UPLOAD_FAILURE} '${fileName}'`
            : `${MESSAGES.ATTACHMENT_UPLOAD_FAILURE}`;

          toast.error(
            <>
              {attachmentMessage}
              {errorMessage && ":"}
              <br />
              {errorMessage}
            </>
          );
        });
      } else {
        toast.dismiss();
        removeAllAttachments();
        shouldRefetch = true;
      }
    }

    if (shouldRefetch) {
      refetch();
    }
    setIsLoading(false);
  };

  return (
    <div className="my-4" data-testid="reply-form">
      <input
        ref={fileInputRef}
        data-testid="file-input"
        className="hidden"
        type="file"
        name="file"
        accept={validMimeTypes}
        multiple={true}
        onChange={attachFile}
        disabled={validMimeTypesLoading}
      />
      <form
        id="reply-form"
        className="flex flex-col"
        onSubmit={handleSubmitReply}
      >
        <ReplyBox
          ref={textBoxRef}
          disabled={isLoading}
          value={replyMessage}
          onChange={handleChange}
          buttons={
            <FormButtons
              fileInput={fileInput}
              isLoading={isLoading || validMimeTypesLoading}
            />
          }
        />
      </form>
    </div>
  );
}

function FormButtons({
  fileInput,
  isLoading,
}: {
  fileInput: FileInputType;
  isLoading: boolean;
}) {
  const { files, openFileMenu, removeAttachment } = fileInput;

  return (
    <>
      {files && files.size > 0 && (
        <div className="flex flex-wrap gap-1 py-1">
          {Array.from(files.entries()).map(([id, file]) => (
            <AttachmentPill
              key={`${id}`}
              loading={isLoading}
              fileName={file.name}
              className={"flex gap-1"}
              onRemove={() => removeAttachment(id)}
            />
          ))}
        </div>
      )}
      <div className="flex">
        <Buttons align="left" className="ml-auto w-full">
          <Button
            data-testid="add-new-attachment-button"
            className="justify-self-start"
            aria-label="upload"
            onClick={openFileMenu}
            disabled={isLoading || files?.size === ATTACHMENT_LIMIT}
            size="small"
            fill="subdued"
          >
            <PaperClipIcon className="h-2 w-2" />
          </Button>
        </Buttons>
        <Button
          className="justify-self-end"
          kind="primary"
          id="send-new-reply-button"
          type="submit"
          loading={isLoading}
          fill="subdued"
          size="small"
        >
          Reply
        </Button>
      </div>
    </>
  );
}
