import clsx from "clsx";
import { ReactNode } from "react";

interface Props {
  actions?: ReactNode;
  className?: string;
  description?: ReactNode;
  heading: ReactNode;
  marginBottom?: "default" | "none";
}

export const PageHeading = ({
  actions = null,
  className,
  description,
  heading,
  marginBottom = "default",
}: Props) => {
  return (
    <div
      className={clsx(
        "flex items-center justify-between",
        {
          "mb-3": marginBottom === "default",
        },
        className
      )}
    >
      <div className="flex flex-col">
        <h3 className="text-base font-semibold">{heading}</h3>
        {description && (
          <div className="text-xs font-semibold text-subdued">
            {description}
          </div>
        )}
      </div>
      {actions}
    </div>
  );
};
