import { InMemoryCacheConfig } from "@m/api";
import { simplePagination } from "@m/api/utils";

/**
 * Arguments that paginate, filter, sort, search, or otherwise reprocess
 * existing field data usually do not belong in keyArgs.
 */

export const cacheConfig: InMemoryCacheConfig = {
  addTypename: false,
  typePolicies: {
    Query: {
      fields: {
        activityStream: simplePagination(),
        autoScalingGroups: simplePagination(),
        cases: simplePagination(["where"]),
        classicLoadBalancers: simplePagination(),
        ec2Instances: simplePagination(),
        ecsClusters: simplePagination(),
        eksClusters: simplePagination(),
        elasticacheClusters: simplePagination(),
        engagement: { merge: true },
        lambdaFunctions: simplePagination(),
        loadBalancers: simplePagination(["loadBalancerType"]),
        rdsInstances: simplePagination(),
        meteredCharges: simplePagination(),
        aggregateMarketplaceMeteringTransactions: simplePagination(),
        viewer: { merge: true },
        userProfile: { merge: true },
      },
    },
    Engagement: {
      fields: {
        riskLogItems: simplePagination(),
        milestones: simplePagination(),
        checklist: simplePagination(),
      },
    },
    ScorecardRecommendation: {
      fields: {
        flaggedResources: simplePagination(),
      },
    },
    ScorecardResult: {
      fields: {
        activityStream: simplePagination(),
        flaggedResources: simplePagination(),
      },
    },
  },
};
