import { useCallback, useState } from "react";

import { downloadFile } from "@m/api/utils";

export const useValidateAndDownloadReport = (downloadId: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isDownloadIdValid, setIsDownloadIdValid] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>(null);

  const validateAndDownloadReport = useCallback(
    ({ isAutoDownload }: { isAutoDownload: boolean }) => {
      // Adding a slight delay here only when the download is auto-started  on page render helps to
      // avoid the error "TypeError: failed to fetch" which seems to occur otherwise with some browsers
      const DOWNLOAD_DELAY_MS = isAutoDownload ? 1000 : 0;

      setLoading(true);

      const downloadMetadataUrl =
        process.env.REACT_APP_SERVICES_API +
        `/api/public/v1/blob/${downloadId}`;

      fetch(downloadMetadataUrl, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.download_url) {
            setLoading(false);
            setIsDownloadIdValid(true);
            setFileName(data.file_name);

            setTimeout(() => {
              downloadFile(
                `${process.env.REACT_APP_SERVICES_API}${data.download_url}`,
                data.file_name
              );
            }, DOWNLOAD_DELAY_MS);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [downloadId]
  );

  return [
    validateAndDownloadReport,
    { isDownloadIdValid, fileName, loading },
  ] as const;
};
