import {
  ClockIcon,
  FingerPrintIcon,
  KeyIcon,
} from "@heroicons/react/24/outline";
import { ElementType, ReactNode, useState } from "react";

import { useAuth } from "@m/login";
import { Banner, Button, Buttons, Card, Link, Section, Toggle } from "@m/ui";

import { Page } from "@mc/components/Page";
import { PageHeading } from "@mc/components/PageHeading";
import { SSO_SESSION_URL } from "@mc/constants";

import { useEnforceMfa } from "../api";

export const SecuritySettingsPage = () => {
  const [enforceMfa, { loading }] = useEnforceMfa();
  const { user: currentUser, updateUserSession } = useAuth();

  const isSSO = currentUser?.company?.sso === true;
  const isMFA = currentUser?.company?.mfa === true;

  const [mfaEnforced, setMfaEnforced] = useState(isMFA);

  const saveMfa = (value: boolean) => {
    setMfaEnforced(value);
    updateUserSession({
      ...currentUser,
      company: {
        ...currentUser.company,
        mfa: value,
      },
    });
    enforceMfa({ required: value });
  };

  return (
    <Page data-testid="security-settings-page">
      <PageHeading
        heading="Security"
        description="Configure security settings for all users in your company"
      />

      <Card className="mb-3">
        <Section
          title="Multi-Factor Authentication"
          description="Add an extra layer of security for users by requiring a two-step verification for all logins."
          tooltip="Only admin users can change this setting."
          actions={
            isSSO ? (
              <div className="flex items-center gap-1">
                <Toggle disabled={true} checked={false} />
                <span className="font-bold text-subdued">Off</span>
              </div>
            ) : (
              <div className="flex items-center gap-1">
                <Toggle
                  disabled={loading}
                  checked={mfaEnforced}
                  onChange={saveMfa}
                />
                <span className="font-bold">{mfaEnforced ? "On" : "Off"}</span>
              </div>
            )
          }
        >
          <div>
            {isSSO ? (
              <Banner
                className="text-xs text-action"
                label="Single Sign-On (SAML) is configured for all users in your company.  Please use your SAML-based single sign-on provider to enforce MFA."
              />
            ) : (
              <div className="flex flex-col gap-1">
                <div className="text-xs font-semibold">
                  Available options include:
                </div>

                <ul className="flex flex-col gap-0.5 text-xs font-medium text-subdued">
                  <ListItem icon={KeyIcon}>
                    WebAuthn with FIDO Security Keys (such as{" "}
                    <Link
                      href="https://www.yubico.com/"
                      rel="noreferrer"
                      target="_blank"
                      className="underline"
                    >
                      Yubikey
                    </Link>{" "}
                    and{" "}
                    <Link
                      href="https://cloud.google.com/security/products/titan-security-key?hl=en"
                      rel="noreferrer"
                      target="_blank"
                      className="underline"
                    >
                      Google Titan
                    </Link>
                    )
                  </ListItem>
                  <ListItem icon={FingerPrintIcon}>
                    <Link
                      href="https://webauthn.me/browser-support"
                      rel="noreferrer"
                      target="_blank"
                      className="underline"
                    >
                      WebAuthn with FIDO Device Biometrics
                    </Link>
                  </ListItem>
                  <ListItem icon={ClockIcon}>
                    One-time Password (such as Google Authenticator and{" "}
                    <Link
                      href="https://1password.com/"
                      rel="noreferrer"
                      target="_blank"
                      className="underline"
                    >
                      1Password
                    </Link>
                    )
                  </ListItem>
                </ul>
              </div>
            )}
          </div>
        </Section>
      </Card>

      {!isSSO && (
        <Card>
          <Section
            title="SAML for Single Sign-On"
            description="Already have an IdP you'd prefer to use? Mission Control supports SAML for Single Sign-On, enabling secure and seamless access."
            actions={
              <div className="flex items-center gap-1">
                <Toggle disabled={true} checked={false} />
                <span className="font-bold text-subdued">Off</span>
              </div>
            }
          >
            <Buttons>
              <Link href={SSO_SESSION_URL}>
                <Button kind="primary">Request SSO Enablement</Button>
              </Link>
              <Link
                href="https://resources.missioncloud.com/en/articles/10244522-configuring-saml-based-sso"
                rel="noreferrer"
                target="_blank"
              >
                <Button kind="secondary">Learn More</Button>
              </Link>
            </Buttons>
          </Section>
        </Card>
      )}
    </Page>
  );
};

const ListItem = ({
  children,
  icon: Icon,
}: {
  children: ReactNode;
  icon: ElementType;
}) => {
  return (
    <li className="flex items-center gap-1">
      <Icon className="h-2.5 w-2.5" />
      <div>{children}</div>
    </li>
  );
};
