import { AccessRequired } from "@m/api/components";

import { Page } from "@mc/components/Page";
import { PageHeading } from "@mc/components/PageHeading";
import { FEATURE_FLAGS } from "@mc/constants";

import {
  MarketplaceSubscriptionCards,
  MeteredChargesSection,
} from "../components";

export const MarketplaceSubscriptionsPage = () => (
  <Page data-testid="marketplace-subscriptions-page">
    <div className="flex flex-col gap-3">
      <PageHeading heading="Subscriptions" marginBottom="none" />
      <MarketplaceSubscriptionCards />
      <AccessRequired
        feature={FEATURE_FLAGS.SETTINGS_ROLLOUT_SUBSCRIPTION_DETAILS_PAGE}
      >
        <MeteredChargesSection />
      </AccessRequired>
    </div>
  </Page>
);
