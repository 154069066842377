import { Card } from "@m/ui";

import { Page } from "@mc/components/Page";
import { PageHeading } from "@mc/components/PageHeading";

import {
  ActiveUsersReport,
  CasesReport,
  TeamAssignmentReport,
} from "../components";

export const StaffReportsPage = () => (
  <Page data-testid="staff-reports-page">
    <PageHeading heading="Reports" />
    <Card className="flex flex-col gap-2 p-3">
      <ActiveUsersReport />
      <TeamAssignmentReport />
      <CasesReport />
    </Card>
  </Page>
);
