import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

import { ApiProvider } from "@m/api";
import { Auth0Provider, AuthProvider } from "@m/login";
import { UIProvider } from "@m/ui";

import { ReportCSPViolationProvider } from "@mc/providers";
import { apiProps } from "@mc/utils/graphql";

export const GlobalProviders = ({ children }) => (
  <Auth0Provider clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}>
    <ApiProvider {...apiProps}>
      <ReportCSPViolationProvider>
        <UIProvider>
          <QueryParamProvider adapter={ReactRouter6Adapter}>
            <AuthProvider>{children}</AuthProvider>
          </QueryParamProvider>
        </UIProvider>
      </ReportCSPViolationProvider>
    </ApiProvider>
  </Auth0Provider>
);
