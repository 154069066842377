import { Table } from "@m/ui";

import {
  ResourceDetails,
  ResourceDetailsDrawer,
} from "./ResourceDetailsDrawer";

interface Props {
  defaultSort: string;
  drawerDetails: ResourceDetails;
  emptyMessage?: string;
  headers: object[];
  label: string;
  loading: boolean;
  onCloseDrawer: () => void;
  onSortChange: (sort: string) => void;
  rows: object[];
  pagination: {
    currentPage: number;
    fetchMore: (...args: any) => Promise<object>;
    pageInfo: object;
    pageSize: number;
    setCurrentPage: (page: number) => void;
    showPagination: boolean;
    totalCount: number;
  };
}

export const ResourcesTable = ({
  defaultSort = "CREATE_TIME_DESC",
  drawerDetails,
  emptyMessage,
  headers,
  label,
  loading,
  onCloseDrawer,
  onSortChange,
  pagination,
  rows = [],
}: Props) => {
  return (
    <>
      <Table
        ariaLabel={`${label} Table`}
        defaultSort={defaultSort}
        emptyMessage={emptyMessage}
        headers={headers}
        loading={loading}
        onSortChange={onSortChange}
        rows={rows}
        {...pagination}
      />
      <ResourceDetailsDrawer
        details={drawerDetails}
        open={!!drawerDetails}
        onClose={onCloseDrawer}
      />
    </>
  );
};
