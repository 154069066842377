import { useMemo } from "react";
import { Params, generatePath, useNavigate, useParams } from "react-router-dom";

import { AccessRequired } from "@m/api/components";
import { Badge, Spinner, useConfirmState } from "@m/ui";
import { formatFullDate, toHyphenLowercase } from "@m/utils";

import { Overlay } from "@mc/components/Overlay";
import { TabData, Tabs } from "@mc/components/Tabs";
import { PATHS } from "@mc/constants";
import { DetailsLayout } from "@mc/layouts";

import {
  FLAGGED_RECOMMENDATION_RESOURCES_DOWNLOAD_QUERY,
  useRecommendationDetails,
  useUpdateCloudScoreRecommendationStatus,
} from "../api";
import { CLOUD_SCORE_MESSAGES } from "../constants";
import { RecommendationStatusIcon } from "../icons";
import { getPillar } from "../utils";

import { CloudScoreOverlayHeader } from "./CloudScoreOverlayHeader";
import { DatetimeField } from "./DatetimeField";
import { DeleteRecommendationButton } from "./DeleteRecommendationButton";
import { DeleteRecommendationDialog } from "./DeleteRecommendationDialog";
import { ExportResourcesButton } from "./ExportResourcesButton";
import { FlaggedRecommendationResourcesTable } from "./FlaggedRecommendationResourcesTable";
import { RecommendationCheckField } from "./RecommendationCheckField";
import { RecommendationReporterField } from "./RecommendationReporterField";
import { UpdateRecommendationButton } from "./UpdateRecommendationButton";
import { UpdateRecommendationStatusButton } from "./UpdateRecommendationStatusButton";

interface RecommendationDetailsOverlayParams extends Params {
  recommendationId: string;
  tabName?: string;
}

export const RecommendationDetailsOverlay = () => {
  const { recommendationId, tabName = "resources" } =
    useParams<RecommendationDetailsOverlayParams>();
  const navigate = useNavigate();

  const deleteDialog = useConfirmState();

  const {
    data: { recommendation, check },
    loading,
    error,
  } = useRecommendationDetails(recommendationId);

  const [updateRecommendationStatus, { loading: statusLoading }] =
    useUpdateCloudScoreRecommendationStatus();

  const handleClose = () => {
    navigate("..");
  };
  const handleTabChange = (tab: TabData) => {
    const path = generatePath(PATHS._CLOUD_SCORE_RECOMMENDATION_DETAILS, {
      recommendationId,
      tabName: tab.name,
    });
    navigate("../" + path);
  };
  const handleClickUpdate = () => {
    const path = generatePath(PATHS._CLOUD_SCORE_UPDATE_RECOMMENDATION, {
      recommendationId,
    });
    navigate("../" + path);
  };

  const downloadFilename = useMemo(() => {
    const pillarName = getPillar(check?.pillar)?.name || "";
    const checkName = check?.name || "";
    const updatedDate = formatFullDate(
      recommendation?.updatedTime,
      "yyyy LL dd"
    );

    return toHyphenLowercase(
      `Recommendation-for--${pillarName}--${checkName}--${updatedDate}`
    );
  }, [check, recommendation]);

  return (
    <>
      <Overlay
        data-testid="recommendation-details-overlay"
        onClose={handleClose}
        open={true}
        title="Recommendation"
      >
        <DetailsLayout
          actions={
            !loading &&
            !error && (
              <>
                <RecommendationReporterField
                  account={recommendation.createdBy}
                />
                <DatetimeField
                  label="Created"
                  isoTime={recommendation.createdTime}
                />
                <RecommendationCheckField check={check} />
                <hr />
                <AccessRequired mutation="updateScorecardRecommendationStatus">
                  <UpdateRecommendationStatusButton
                    recommendationId={recommendation.id}
                    scorecardRecStatus={recommendation.scorecardRecStatus}
                    onUpdateStatus={updateRecommendationStatus}
                    loading={statusLoading}
                  />
                </AccessRequired>
                <div aria-label="Actions" className="space-y-0.5">
                  <div className="text-xs font-semibold text-default">
                    Actions
                  </div>
                  <ExportResourcesButton
                    id={recommendationId}
                    name={downloadFilename}
                    totalResources={recommendation.flaggedResources.totalCount}
                    query={FLAGGED_RECOMMENDATION_RESOURCES_DOWNLOAD_QUERY}
                  />
                  <AccessRequired mutation="updateScorecardRecommendation">
                    <UpdateRecommendationButton onClick={handleClickUpdate} />
                  </AccessRequired>
                  <AccessRequired mutation="deleteScorecardRecommendation">
                    <DeleteRecommendationButton
                      onClick={deleteDialog.open}
                      disabled={statusLoading}
                    />
                  </AccessRequired>
                </div>
              </>
            )
          }
        >
          {!loading && !error && (
            <>
              <CloudScoreOverlayHeader
                name={recommendation.title}
                description={recommendation.notes}
                icon={
                  <RecommendationStatusIcon
                    status={recommendation.scorecardRecStatus}
                  />
                }
              />
              <Tabs
                activeTabName={tabName}
                className="bg-white pl-3"
                onTabChange={handleTabChange}
                tabsData={[
                  {
                    name: "resources",
                    label: (
                      <div className="flex space-x-1">
                        <div>Resources</div>
                        <Badge
                          label={recommendation.flaggedResources.totalCount}
                          status="active"
                          strong
                        />
                      </div>
                    ),
                  },
                ]}
              />
              <div className="p-3">
                {tabName === "resources" && (
                  <FlaggedRecommendationResourcesTable
                    recommendationId={recommendation.id}
                    resourceColumns={
                      recommendation.scorecardResult.flaggedResourceFields
                    }
                  />
                )}
              </div>
            </>
          )}

          {!loading && error && (
            <div className="flex h-full items-center justify-center text-subdued">
              {CLOUD_SCORE_MESSAGES.RECOMENDATION_DETAILS_FAILURE}
            </div>
          )}

          {loading && (
            <div className="flex h-full items-center justify-center">
              <Spinner />
            </div>
          )}
        </DetailsLayout>
      </Overlay>

      {deleteDialog.isOpen && (
        <DeleteRecommendationDialog
          id={recommendationId}
          open={deleteDialog.isOpen}
          onClose={deleteDialog.close}
          onCompleted={() => navigate(-1)}
          title={recommendation.title}
        />
      )}
    </>
  );
};
