import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import { INITIAL_PAGINATION, toast, usePaginationProps } from "@m/ui";

export const METERED_CHARGES_TRANSACTIONS_FAILURE_MESSAGE =
  "Unable to get subscription transactions";

export const GET_METERED_CHARGES_TRANSACTIONS = gql(/* GraphQL */ `
  query GetMarketplaceMeteredCharges(
    $startDate: ArrowTypeScalar!
    $endDate: ArrowTypeScalar!
    $first: Int
    $last: Int
    $before: String
    $after: String
    $dimensionCode: String
    $productCode: String
  ) {
    meteredCharges(
      startDate: $startDate
      endDate: $endDate
      first: $first
      last: $last
      before: $before
      after: $after
      dimensionCode: $dimensionCode
      productCode: $productCode
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          transactionId
          companyId
          companyName
          product
          dimension
          chargeDate
          units
          usageDate
        }
      }
    }
    dimensionTotals(startDate: $startDate, endDate: $endDate) {
      dimensionCode
      dimensionDescription
      dimensionTotal
    }
  }
`);

export const useMeteredCharges = (query) => {
  const onError = () =>
    toast.error(METERED_CHARGES_TRANSACTIONS_FAILURE_MESSAGE);

  const { startDate, endDate, dimensionCode, productCode } = query;

  const { data, fetchMore, ...result } = useQuery(
    GET_METERED_CHARGES_TRANSACTIONS,
    {
      onError,
      variables: {
        startDate,
        endDate,
        dimensionCode,
        productCode,
        ...INITIAL_PAGINATION,
      },
    }
  );

  const {
    edges = [],
    pageInfo = {},
    totalCount = 0,
  } = data?.meteredCharges ?? {};

  const { dimensionTotals = [] } = data ?? {};

  const meteredCharges =
    edges
      .map((edge) => {
        return edge?.node;
      })
      .filter((charge) => charge !== null) ?? [];

  const pagination = usePaginationProps({
    pageInfo,
    totalCount,
    fetchMore,
  });

  return {
    data: { meteredCharges, dimensionTotals },
    pagination,
    ...result,
  };
};
