import { useQuery } from "@m/api";
import { gql } from "@m/api/public";

export const GET_COMPANY_PRODUCTS_DIMENSIONS = gql(/* GraphQL */ `
  query GetCompanyProductsDimensions {
    userProfile {
      currentCompany {
        marketplaceRegistrations {
          edges {
            node {
              onboardingCompleteTimestamp
              catalogItem {
                id
                productCode
                displayName
                dimensions {
                  edges {
                    node {
                      id
                      displayName
                      dimensionTypeId
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`);

export const useCompanyProductsDimensions = () => {
  const { data, loading, error } = useQuery(GET_COMPANY_PRODUCTS_DIMENSIONS);
  let earliestOnboardingTimestamp;

  const products =
    data?.userProfile?.currentCompany?.marketplaceRegistrations?.edges?.flatMap(
      (edge) => {
        const { catalogItem, onboardingCompleteTimestamp } = edge?.node || {};
        const { productCode, displayName } = catalogItem || {};

        if (
          !earliestOnboardingTimestamp ||
          earliestOnboardingTimestamp > onboardingCompleteTimestamp
        ) {
          earliestOnboardingTimestamp = onboardingCompleteTimestamp;
        }

        return {
          productCode,
          displayName,
        };
      }
    ) || [];

  const dimensions =
    data?.userProfile?.currentCompany?.marketplaceRegistrations?.edges?.flatMap(
      (edge) =>
        edge?.node?.catalogItem?.dimensions.edges.map((edge) => edge.node)
    ) || [];

  return {
    data: { products, dimensions, earliestOnboardingTimestamp },
    loading,
    error,
  };
};
