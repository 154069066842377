import { BoltIcon } from "@heroicons/react/24/outline";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { StringParam, useQueryParams, withDefault } from "use-query-params";

import { Card, Link, Select } from "@m/ui";
import { dt } from "@m/utils";

import { PageHeading } from "@mc/components/PageHeading";

import { useCompanyProductsDimensions, useMeteredCharges } from "../api";

import { MeteredChargesTable, MeteredChargesTableFilter } from "./";

const METERED_CHARGES_HELP_ARTICLE_LINK =
  "https://resources.missioncloud.com/en/articles/10548263";

export const MeteredChargesSection = () => {
  const [query, setQuery] = useQueryParams({
    productCode: withDefault(StringParam, null),
    dimensionCode: withDefault(StringParam, null),
    startDate: withDefault(
      StringParam,
      dt.now().startOf("month").toFormat("yyyy-MM-dd")
    ),
    endDate: withDefault(
      StringParam,
      dt.now().endOf("month").toFormat("yyyy-MM-dd")
    ),
  });

  const handleQueryChange = (query) => {
    setQuery(query);
  };

  const {
    data: { products, dimensions, earliestOnboardingTimestamp },
    loading: companyProductsDimensionsLoading,
  } = useCompanyProductsDimensions();

  const {
    data: { meteredCharges, dimensionTotals },
    pagination,
    loading: chargesLoading,
  } = useMeteredCharges(query);

  const isLoading = chargesLoading || companyProductsDimensionsLoading;

  const monthOptions = useMemo(() => {
    const currentDate = dt.now().startOf("month");
    let date = dt.fromISO(earliestOnboardingTimestamp).startOf("month");

    const options = [];

    while (date <= currentDate) {
      options.push({
        label:
          date.hasSame(currentDate, "month") &&
          date.hasSame(currentDate, "year")
            ? "Current (unbilled)"
            : date.toFormat("MMMM yyyy"),
        startDate: date.toFormat("yyyy-MM-01"),
        endDate: date.endOf("month").toFormat("yyyy-MM-dd"),
      });

      date = date.plus({ months: 1 });
    }

    return options.reverse();
  }, [earliestOnboardingTimestamp]);

  const [selectedBillingPeriod, setSelectedBillingPeriod] = useState(
    monthOptions[0]
  );

  // If a month filter has already been applied via query params, we'll set the selected month based on the values
  useEffect(() => {
    const billingPeriod =
      query.startDate && query.endDate
        ? monthOptions.find(
            (option) =>
              option.startDate === query.startDate &&
              option.endDate === query.endDate
          )
        : monthOptions[0];
    setSelectedBillingPeriod(billingPeriod);
  }, [monthOptions, query]);

  const handleBillingPeriodChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedMonth = monthOptions.find(
      (option) => option.label === event.target.value
    );
    const { startDate, endDate } = selectedMonth;

    setSelectedBillingPeriod(selectedMonth || monthOptions[0]);
    handleQueryChange({ ...query, startDate, endDate });
  };

  const handleResetFilters = () => {
    setSelectedBillingPeriod(monthOptions[0]);
    handleQueryChange({
      startDate: null,
      endDate: null,
      productCode: null,
      dimensionCode: null,
    });
  };

  return (
    <div data-testid="metered-charges-section" className="flex flex-col gap-3">
      <div className="flex w-full flex-col">
        <PageHeading heading="Marketplace Charges Summary" />
        <div>
          Your Mission subscription is charged daily based on usage. See your
          marketplace agreement for details or learn more about{" "}
          <Link href={METERED_CHARGES_HELP_ARTICLE_LINK} target="_blank">
            Marketplace Charges Summary.
          </Link>
        </div>
      </div>
      <div className="flex w-1/3">
        <span className="whitespace-nowrap font-semibold text-subdued">
          Selected month
        </span>
        <Select
          className="mx-1 w-[200px] p-0"
          id="metered-charges-month-selector"
          data-testid="metered-charges-month-selector"
          name="billed-month"
          onChange={handleBillingPeriodChange}
          options={monthOptions.map(({ label }) => (
            <option key={label} value={label}>
              {label}
            </option>
          ))}
          value={selectedBillingPeriod?.label}
        />
      </div>
      <div className="grid h-fit grid-cols-2 gap-2">
        {dimensionTotals.map(
          ({ dimensionCode, dimensionDescription, dimensionTotal }) =>
            dimensionTotal && (
              <Card key={dimensionCode} className="flex h-full gap-1 p-1.5">
                <BoltIcon className="h-2.5 w-2.5" />
                <span className="font-semibold">
                  {dimensionDescription}:
                </span>{" "}
                {dimensionTotal?.toLocaleString()} Units Metered
              </Card>
            )
        )}
      </div>
      <MeteredChargesTableFilter
        query={query}
        dimensions={dimensions}
        eligibleProducts={products}
        handleQueryChange={handleQueryChange}
        handleResetFilters={handleResetFilters}
        loading={isLoading}
      />
      <MeteredChargesTable
        meteredCharges={meteredCharges}
        isLoading={isLoading}
        {...pagination}
      />
    </div>
  );
};
