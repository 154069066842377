import { useMutation } from "@apollo/client";

import { gql } from "@m/api/public";
import {
  UpdateAwsAccountInput,
  UpdateAwsAccountMutation,
} from "@m/api/public/types";
import { toast } from "@m/ui";

export const UPDATE_AWS_ACCOUNT_NAME = gql(/* GraphQL */ `
  mutation updateAwsAccount($input: UpdateAwsAccountInput!) {
    updateAwsAccount(input: $input) {
      ok
      message
    }
  }
`);

export const useUpdateAwsAccount = () => {
  const handleError = () =>
    toast.error("Unable to rename AWS account. Please try again later.");

  const [mutate] = useMutation(UPDATE_AWS_ACCOUNT_NAME, {
    onCompleted: (data: UpdateAwsAccountMutation) => {
      if (!data.updateAwsAccount.ok) return handleError();
      toast.success("The account name was changed successfully.");
    },
    onError: handleError,
    refetchQueries: ["AwsAccounts"],
    awaitRefetchQueries: true,
  });

  const updateAwsAccount = ({ id, displayName }: UpdateAwsAccountInput) =>
    mutate({
      variables: { input: { id, displayName } },
    });

  return [updateAwsAccount] as const;
};
