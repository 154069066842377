import { RouteObject } from "react-router-dom";

import { PATHS } from "@mc/constants";
import { MyTeamPage } from "@mc/features/MyTeam";
import { MarketplaceInvitationsPage } from "@mc/features/Onboarding";
import { StaffReportsPage } from "@mc/features/Reports";
import { NotFound } from "@mc/pages/404";
import { Navigate } from "@mc/router";

export const staffRoutes: RouteObject[] = [
  { index: true, element: <Navigate replace to={PATHS.STAFF_INVITATIONS} /> },
  { path: "reports", element: <StaffReportsPage /> },
  { path: "invitations", element: <MarketplaceInvitationsPage /> },
  { path: "team", element: <MyTeamPage /> },
  { path: "*", element: <NotFound /> },
];
