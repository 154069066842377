export const MUTATIONS = {
  CREATE_AWS_ACCOUNT_ONBOARDING_REQUEST: "createAwsAccountOnboardingRequest",
  CREATE_CASE: "createCase",
  CREATE_ENGAGEMENT_WITH_TEMPLATE: "createEngagementWithTemplate",
  CREATE_SCORECARD_RECOMMENDATION: "createScorecardRecommendation",
  SET_COMPANY_BOOKMARKS: "setCompanyBookmarks",
  UPDATE_CHECK_SOURCE_IGNORE: "updateCheckSourceIgnore",
  UPDATE_ENGAGEMENT: "updateEngagement",
  UPDATE_MILESTONE: "updateMilestone",
  UPDATE_RISK_LOG_ITEM: "updateRiskLogItem",
  UPDATE_ACTION_ITEM: "updateChecklistItem",
  UPDATE_DECISION_ITEM: "updateEngagementDecision",
  UPDATE_PAYMENT_PROFILE: "updatePaymentProfile",
  UPDATE_SCORECARD_CONFIGURATION: "updateScorecardIgnoreAccountStatus",
  UPDATE_AWS_ACCOUNT: "updateAwsAccount",
  ENFORCE_MFA: "enforceMfa",
};
