import {
  ArrowTopRightOnSquareIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";

import { SyncDisableReasonString } from "@m/api/public/types";
import { Link, Spinner, Timestamp, Tooltip } from "@m/ui";

import { PageTitle } from "@mc/components/PageTitle";
import { PATHS } from "@mc/constants";
import { useAwsAccounts } from "@mc/features/AccountSettings/api";

import {
  ACCOUNTS_NOT_SYNCED,
  ALL_ACCOUNTS_DISABLED,
  SOME_ACCOUNTS_DISABLED,
} from "../constants";
import { getLeastRecentSync } from "../utils";

interface Props {
  title: string;
}

export const ResourcesPageHeader = ({ title }: Props) => {
  const {
    loading,
    data: { accounts = [] },
  } = useAwsAccounts();

  const [leastRecentSync, message] = getLastUpdateMessage(accounts);

  return (
    <PageTitle
      breadcrumbs={[
        {
          path: null,
          name: "Resources",
        },
        { path: null, name: title },
      ]}
      padding="small"
      actions={
        loading ? (
          <Spinner className="text-accent" size={2.5} />
        ) : (
          <div className="flex flex-col justify-center">
            <div className="flex flex-row justify-center gap-1 text-xs font-medium text-subdued">
              {message && (
                <Tooltip
                  className="shadow-sm"
                  content={<Message message={message} />}
                  place="right"
                  side="right"
                >
                  <ExclamationTriangleIcon
                    className="h-2.5 w-2.5 text-amber-500"
                    aria-label="Account Warning"
                  />
                </Tooltip>
              )}
              {accounts.length === 0 && (
                <span className="my-auto">No AWS Accounts</span>
              )}

              <Timestamp
                isoTime={leastRecentSync}
                verbiage="Updated"
                side="bottom"
                longFormat={true}
              />
            </div>
          </div>
        )
      }
    />
  );
};

const getLastUpdateMessage = (awsAccounts) => {
  const enabledAccounts = [];
  const notYetSynced = [];
  const disabledAccounts = [];
  for (const account of awsAccounts) {
    if (account.syncsEnabled) {
      enabledAccounts.push(account);
      if (account.lastCompletedSynced === null) notYetSynced.push(account);
    } else if (
      account.syncDisableReasonString !==
      SyncDisableReasonString.AccountDisabled
    ) {
      disabledAccounts.push(account);
    }
  }

  const leastRecentSync = getLeastRecentSync(enabledAccounts);

  let message;
  if (disabledAccounts.length > 0) {
    if (enabledAccounts.length === 0) {
      message = ALL_ACCOUNTS_DISABLED;
    } else {
      message = SOME_ACCOUNTS_DISABLED;
    }
  } else if (notYetSynced.length === enabledAccounts.length) {
    message = ACCOUNTS_NOT_SYNCED;
  }

  if (awsAccounts.length === 0) {
    message = (
      <div className="text-left">
        <span>You have no AWS accounts linked to Mission Control.</span>
        <div>
          View AWS accounts on the{" "}
          <Link to={PATHS.SETTINGS_ACCOUNTS} className="text-white underline">
            company settings page{" "}
            <ArrowTopRightOnSquareIcon className="mb-0.5 inline-flex h-2 w-2" />
          </Link>
        </div>
      </div>
    );
  }

  return [leastRecentSync, message];
};

const Message = ({ message }) => {
  return <div className="max-w-xs text-center font-semibold">{message}</div>;
};
