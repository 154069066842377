export const MESSAGES = {
  ATTACHMENT_UPLOAD_FAILURE: "Unable to upload your file",
  ATTACHMENT_DELETE_FAILURE: "Unable to delete this attachment",
  ATTACHMENT_DOWNLOADING: "Your download will start momentarily.",
  ATTACHMENT_PENDING: "This file is still being processed. Check back soon.",
  INTERCOM_MESSAGE_FAILURE: "Unable to load Intercom messages",
  COMMENT_CREATION_FAILURE: "Unable to add your reply",
  VALID_MIME_TYPES_FAILURE:
    "Unable to retrieve list of valid attachment file types",
};
