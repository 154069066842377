import { CheckBadgeIcon } from "@heroicons/react/24/outline";
import { useMemo, useState } from "react";
import {
  ArrayParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

import {
  FilterBar,
  FilterSelect,
  Search,
  createFilterOptions,
  useSearchTerm,
} from "@m/ui";
import { formatFullDate } from "@m/utils";

import { Page } from "@mc/components/Page";

import {
  useRdsInstances,
  useRdsInstancesFilters,
  useRdsInstancesSearchTerms,
} from "../api";
import {
  ResourceDetails,
  ResourceTagsBadge,
  ResourcesPageHeader,
  ResourcesTable,
} from "../components";
import {
  AWS_RESOURCE_STATUS_TAGS,
  GET_RESOURCES_EMPTY,
  MISSION_MANAGED_FILTER_ICON,
  MISSION_MANAGED_FILTER_LABEL,
  MISSION_MANAGED_FILTER_OPTIONS,
  RDS_LABEL,
  TABLE_HEADERS_RDS_INSTANCES,
} from "../constants";
import { createAccountOptions } from "../utils";

export const RdsInstancesPage = () => {
  const [query, setQuery] = useQueryParams({
    missionManaged: StringParam,
    accounts: ArrayParam,
    zones: ArrayParam,
    statuses: ArrayParam,
    classes: ArrayParam,
    isMultiAz: StringParam,
    isEncrypted: StringParam,
    engines: ArrayParam,
    sort: withDefault(StringParam, "CREATE_TIME_DESC"),
    search: StringParam,
  });

  const {
    data: {
      filters: { accounts, zones, statuses, classes, engines },
    },
  } = useRdsInstancesFilters();

  const {
    data: { searchTerms },
    refetch: refetchSearchTerms,
  } = useRdsInstancesSearchTerms(query.search);

  const {
    searchTerm,
    handleSearchInputChange,
    handleSubmitSearch,
    clearSearchTerm,
  } = useSearchTerm({ setQuery, refetchSearchTerms, query });

  /** keeping the query for the resource last simplifies testing with calls.lastQuery */
  const {
    data: { rdsInstances },
    loading,
    pagination,
  } = useRdsInstances(query);

  const [drawerDetails, setDrawerDetails] = useState<ResourceDetails | null>(
    null
  );

  const handleSortChange = (sort: string) => {
    pagination.setCurrentPage(1);
    setQuery({ sort });
  };

  const rows = useMemo(
    () =>
      rdsInstances.map((rdsInstance) => {
        const {
          tags = "{}",
          instanceId = "",
          createTime,
          isMultiAz = false,
          isEncrypted = false,
          backupPolicy = "",
          monitoringPolicy = "",
          tagTotal,
        } = rdsInstance || {};

        const tagsData = JSON.parse(tags);
        const formattedTime = formatFullDate(createTime);
        const backupPolicyTag = AWS_RESOURCE_STATUS_TAGS[backupPolicy] || "";
        const monitoringPolicyTag =
          AWS_RESOURCE_STATUS_TAGS[monitoringPolicy] || "";
        const multiAzBadge = isMultiAz && (
          <CheckBadgeIcon
            className="h-3 w-3 text-green-500"
            aria-label="multi-az"
          />
        );
        const encryptedBadge = isEncrypted && (
          <CheckBadgeIcon
            className="h-3 w-3 text-green-500"
            aria-label="encrypted"
          />
        );

        return {
          ...rdsInstance,
          tags: (
            <ResourceTagsBadge
              onClick={setDrawerDetails}
              details={{
                tags: tagsData,
                name: instanceId,
              }}
              count={tagTotal}
            />
          ),
          createTime: formattedTime,
          isMultiAz: multiAzBadge,
          isEncrypted: encryptedBadge,
          backupPolicy: backupPolicyTag,
          monitoringPolicy: monitoringPolicyTag,
        };
      }),
    [rdsInstances]
  );

  const handleClearFilters = () => {
    clearSearchTerm();

    setQuery({
      missionManaged: undefined,
      accounts: undefined,
      zones: undefined,
      statuses: undefined,
      classes: undefined,
      isMultiAz: undefined,
      isEncrypted: undefined,
      engines: undefined,
      search: undefined,
    });
  };

  const handleSelectManaged = (missionManaged: string) => {
    if (missionManaged === query.missionManaged) {
      setQuery({
        missionManaged: undefined,
      });
    } else {
      setQuery({
        missionManaged: missionManaged,
      });
    }
  };

  const handleDeselectManagedFilter = () => {
    setQuery({
      missionManaged: undefined,
    });
  };

  const handleSelectedAccountNames = (accountIds: string[]) => {
    setQuery({
      accounts: accountIds,
    });
  };

  const handleDeselectAccountsFilter = () => {
    setQuery({
      accounts: undefined,
    });
  };

  const handleSelectedStatus = (statuses: string[]) => {
    setQuery({
      statuses: statuses,
    });
  };

  const handleDeselectStatusFilter = () => {
    setQuery({
      statuses: undefined,
    });
  };

  const handleSelectedClasses = (classes: string[]) => {
    setQuery({
      classes,
    });
  };

  const handleDeselectClassesFilter = () => {
    setQuery({
      classes: undefined,
    });
  };

  const handleSelectedZones = (zones: string[]) => {
    setQuery({
      zones,
    });
  };

  const handleDeselectZonesFilter = () => {
    setQuery({
      zones: undefined,
    });
  };

  const handleSelectMultiAz = (isMultiAz: string) => {
    if (isMultiAz === query.isMultiAz) {
      setQuery({
        isMultiAz: undefined,
      });
    } else {
      setQuery({
        isMultiAz: isMultiAz,
      });
    }
  };

  const handleDeselectMultiAzFilter = () => {
    setQuery({
      isMultiAz: undefined,
    });
  };

  const handleSelectEncrypted = (isEncrypted: string) => {
    if (isEncrypted === query.isEncrypted) {
      setQuery({
        isEncrypted: undefined,
      });
    } else {
      setQuery({
        isEncrypted: isEncrypted,
      });
    }
  };

  const handleDeselectEncryptedFilter = () => {
    setQuery({
      isEncrypted: undefined,
    });
  };

  const handleSelectedEngines = (engines: string[]) => {
    setQuery({
      engines,
    });
  };

  const handleDeselectEnginesFilter = () => {
    setQuery({
      engines: undefined,
    });
  };

  const hasActiveFilters =
    !!query.missionManaged ||
    query.accounts?.length > 0 ||
    query.zones?.length > 0 ||
    query.statuses?.length > 0 ||
    query.classes?.length > 0 ||
    !!query.isMultiAz ||
    !!query.isEncrypted ||
    query.engines?.length > 0 ||
    !!searchTerm;

  return (
    <Page width="full" title={<ResourcesPageHeader title={RDS_LABEL} />}>
      <FilterBar
        isActive={hasActiveFilters}
        onResetFilters={handleClearFilters}
        className="mb-2 p-1"
      >
        <Search
          ariaLabel={`${RDS_LABEL} Search Input`}
          dropdownOptions={searchTerms}
          handleSearchInputChange={handleSearchInputChange}
          handleSubmitSearch={handleSubmitSearch}
          searchTerm={searchTerm}
        />
        <FilterSelect
          ariaLabel={MISSION_MANAGED_FILTER_LABEL}
          disabled={loading}
          icon={MISSION_MANAGED_FILTER_ICON}
          initialValue={MISSION_MANAGED_FILTER_OPTIONS.MANAGED}
          onChange={handleSelectManaged}
          onClear={handleDeselectManagedFilter}
          options={createFilterOptions(
            Object.values(MISSION_MANAGED_FILTER_OPTIONS)
          )}
          displayValue={query.missionManaged}
          selection={query.missionManaged || []}
        />
        <FilterSelect
          ariaLabel="Accounts Filter"
          disabled={accounts.length === 0 || loading}
          initialValue="Accounts"
          multiple={true}
          onChange={handleSelectedAccountNames}
          onClear={handleDeselectAccountsFilter}
          options={createAccountOptions(accounts)}
          selection={query.accounts || []}
        />
        <FilterSelect
          ariaLabel="Zones Filter"
          disabled={zones.length === 0 || loading}
          initialValue="Zone"
          multiple={true}
          onChange={handleSelectedZones}
          onClear={handleDeselectZonesFilter}
          options={createFilterOptions(zones)}
          selection={query.zones || []}
        />
        <FilterSelect
          ariaLabel="Statuses Filter"
          disabled={statuses.length === 0 || loading}
          initialValue="Status"
          multiple={true}
          onChange={handleSelectedStatus}
          onClear={handleDeselectStatusFilter}
          options={createFilterOptions(statuses)}
          selection={query.statuses || []}
        />
        <FilterSelect
          ariaLabel="Classes Filter"
          disabled={classes.length === 0 || loading}
          initialValue="Class"
          multiple={true}
          onChange={handleSelectedClasses}
          onClear={handleDeselectClassesFilter}
          options={createFilterOptions(classes)}
          selection={query.classes || []}
        />
        <FilterSelect
          ariaLabel="Multi AZ Filter"
          disabled={accounts.length === 0 || loading}
          initialValue="Multi AZ"
          onChange={handleSelectMultiAz}
          onClear={handleDeselectMultiAzFilter}
          options={createFilterOptions(["Multiple Zones", "Single Zone"])}
          displayValue={query.isMultiAz}
          selection={query.isMultiAz || []}
        />
        <FilterSelect
          ariaLabel="Encrypted Filter"
          disabled={accounts.length === 0 || loading}
          initialValue="Encrypted"
          onChange={handleSelectEncrypted}
          onClear={handleDeselectEncryptedFilter}
          options={createFilterOptions(["Encrypted", "Unencrypted"])}
          displayValue={query.isEncrypted}
          selection={query.isEncrypted || []}
        />
        <FilterSelect
          ariaLabel="Engines Filter"
          disabled={classes.length === 0 || loading}
          initialValue="Engine"
          multiple={true}
          onChange={handleSelectedEngines}
          onClear={handleDeselectEnginesFilter}
          options={createFilterOptions(engines)}
          selection={query.engines || []}
        />
      </FilterBar>
      <ResourcesTable
        defaultSort={query.sort}
        drawerDetails={drawerDetails}
        emptyMessage={GET_RESOURCES_EMPTY(RDS_LABEL)}
        headers={TABLE_HEADERS_RDS_INSTANCES}
        label={RDS_LABEL}
        loading={loading}
        onCloseDrawer={() => setDrawerDetails(null)}
        onSortChange={handleSortChange}
        pagination={pagination}
        rows={rows}
      />
    </Page>
  );
};
