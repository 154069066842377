import { RouteObject } from "react-router-dom";

import { ReportsDownloadPage } from "@mc/features/Reports";
import { NotFound } from "@mc/pages/404";

export const reportsRoutes: RouteObject[] = [
  {
    path: "download/:downloadId",
    element: <ReportsDownloadPage />,
  },
  { path: "*", element: <NotFound /> },
];
