import { DocumentNode } from "graphql";
import { ReactNode } from "react";

import { Button } from "@m/ui";

import { useCSVDownload } from "@mc/hooks";

interface DownloadableReportProps {
  title: string;
  description: string;
  query: DocumentNode;
  variables: Record<string, unknown>;
  context: { clientName?: string };
  options: ReactNode;
}

export function DownloadableReport({
  title,
  description,
  query,
  variables,
  context = {},
  options,
}: DownloadableReportProps) {
  const filename = `${title.toLowerCase().replace(" ", "-")}.csv`;
  const [fetch, { loading }] = useCSVDownload({
    filename,
    query,
    ...(variables && { variables: variables }),
    context,
  });
  return (
    <div className="flex items-center justify-between">
      <div>
        <h3 className="p-0 text-base font-semibold">{title}</h3>
        <div>{description}</div>
        {options && <div className="mt-2">{options}</div>}
      </div>
      <Button onClick={fetch} loading={loading}>
        Download
      </Button>
    </div>
  );
}
