import { useCallback, useState } from "react";

interface RowSelectProps {
  isAllRowsSelected: boolean;
  selectedRowIds: string[];
  selectInitialRows: (rowIds: string[]) => void;
  showSelectAll: boolean;
  toggleAllRowsSelected: (checked: boolean) => void;
  toggleRowSelected: (rowId: string, checked: boolean) => void;
}

export const useRowSelectProps = (defaultSelectAll = false): RowSelectProps => {
  const [isAllRowsSelected, setIsAllRowsSelected] =
    useState<boolean>(defaultSelectAll);
  const [selectedRowIds, setSelectedRowIds] = useState<Set<string>>(new Set());
  const [selectedRowsDirty, setSelectedRowsDirty] = useState<boolean>(false);

  const selectInitialRows = useCallback(
    (rowIds: string[]) => {
      if (!selectedRowsDirty) {
        setIsAllRowsSelected(false);
        setSelectedRowIds(new Set(rowIds));
        setSelectedRowsDirty(true);
      }
    },
    [selectedRowsDirty]
  );

  const selectRow = useCallback(
    (rowId: string) => setSelectedRowIds((prev) => new Set(prev).add(rowId)),
    []
  );

  const deselectRow = useCallback(
    (rowId: string) =>
      setSelectedRowIds((prev) => {
        prev.delete(rowId);
        return new Set(prev);
      }),
    []
  );

  const toggleAllRowsSelected = useCallback((checked: boolean) => {
    setSelectedRowIds(new Set());
    setIsAllRowsSelected(checked);
  }, []);

  const toggleRowSelected = useCallback(
    (rowId: string, checked: boolean) => {
      let isSelect = checked;
      if (isAllRowsSelected) isSelect = !isSelect;

      if (isSelect) selectRow(rowId);
      else deselectRow(rowId);
    },
    [deselectRow, isAllRowsSelected, selectRow]
  );

  return {
    isAllRowsSelected,
    selectedRowIds: Array.from(selectedRowIds),
    selectInitialRows,
    showSelectAll: true,
    toggleAllRowsSelected,
    toggleRowSelected,
  };
};
