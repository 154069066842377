import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useAuth } from "@m/login";
import { Button, Spinner } from "@m/ui";

import { Page } from "@mc/components/Page";
import { PageTitle } from "@mc/components/PageTitle";
import { PATHS } from "@mc/constants";
import { NotFound } from "@mc/pages";
import { generateCompanyPath } from "@mc/utils/companyPath";

import { useValidateAndDownloadReport } from "../api/useValidateAndDownloadReport";

export const ReportsDownloadPage = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const params = useParams<{ downloadId: string }>();
  const downloadId = params.downloadId;

  const [validateAndDownloadReport, { isDownloadIdValid, fileName, loading }] =
    useValidateAndDownloadReport(downloadId);

  useEffect(() => {
    validateAndDownloadReport({ isAutoDownload: true });
  }, [validateAndDownloadReport]);

  if (!loading && !isDownloadIdValid) {
    return <NotFound />;
  }

  return (
    <Page
      data-testid="reports-download-page"
      className="flex flex-col items-center justify-center gap-4"
      title={<PageTitle title="Download Resources Report" padding="small" />}
    >
      {!fileName && loading ? (
        <Spinner />
      ) : (
        <div className="flex flex-col items-center justify-center p-4">
          <div className="flex flex-col items-center justify-center p-4">
            <div className="text-lg font-semibold">Your report is ready!</div>
            <div className="w-3/4 text-center">
              The download for <span className="font-semibold">{fileName}</span>{" "}
              will start automatically. If this doesn’t happen, click the button
              below.
            </div>
          </div>

          <div className="flex gap-3">
            <Button
              onClick={() => {
                navigate(generateCompanyPath(PATHS.ROOT, user.company.slug));
              }}
            >
              Return to Mission Control
            </Button>
            <Button
              data-testid="report-download-button"
              onClick={validateAndDownloadReport}
              kind="primary"
            >
              Download report
            </Button>
          </div>
        </div>
      )}
    </Page>
  );
};
