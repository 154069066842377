import { PencilIcon } from "@heroicons/react/20/solid";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useMemo, useRef, useState } from "react";

import { AccessRequired } from "@m/api/components";
import { AwsAccount, AwsAccountOnboardingStatus } from "@m/api/public/types";
import {
  Badge,
  Button,
  Confirm,
  Input,
  Link,
  Table,
  useModalState,
} from "@m/ui";
import { useEscapeListener } from "@m/utils";

import { Page } from "@mc/components/Page";
import { PageHeading } from "@mc/components/PageHeading";
import { FEATURE_FLAGS, MUTATIONS, PATHS } from "@mc/constants";
import { sortNameAsc } from "@mc/utils/sort";

import { useAwsAccounts } from "../api";
import { useUpdateAwsAccount } from "../api/useUpdateAwsAccount";
import { AccountBanner, AccountConsole, AccountStatus } from "../components";

export const AccountsPage = () => {
  const {
    loading,
    data: { accounts },
  } = useAwsAccounts();

  const rows = useMemo(() => {
    return accounts
      .sort(sortByAccountName) // first, sort accounts by name
      .sort((a, b) => Number(isPayerAccount(b)) - Number(isPayerAccount(a))) // move payer account(s) to top of list
      .sort((a, b) => Number(isOnboarded(b)) - Number(isOnboarded(a))) // move accounts that need onboarding to bottom of list
      .map((account) => ({
        accountId: (
          <AccountId
            accountId={account.databaseId}
            isOnboarded={isOnboarded(account)}
          />
        ),
        accountName: (
          <AccountName
            accountId={account.databaseId}
            accountName={account.displayName || account.name}
            isOnboarded={isOnboarded(account)}
          />
        ),
        isPayerAccount: (
          <>
            {isPayerAccount(account) && (
              <Badge
                label="Payer Account"
                status="positive"
                size="small"
                strong={false}
              />
            )}
          </>
        ),
        status: (
          <AccountStatus
            awsAccountId={account.databaseId}
            isOnboarded={isOnboarded(account)}
            isSyncing={account.isSyncing}
            lastCompletedSynced={account.lastCompletedSynced}
            syncDisableReasonString={account.syncDisableReasonString}
            syncsEnabled={account.syncsEnabled}
          />
        ),
        console: <AccountConsole awsAccountId={account.databaseId} />,
      }));
  }, [accounts]);

  return (
    <Page data-testid="accounts-page" className="flex flex-col">
      <PageHeading
        heading="AWS Accounts"
        actions={
          <AccessRequired
            feature={FEATURE_FLAGS.ACCOUNTS_ALLOW_ADD_AWS_ACCOUNT}
            mutation={MUTATIONS.CREATE_AWS_ACCOUNT_ONBOARDING_REQUEST}
          >
            <Button
              to={PATHS.SETTINGS_ACCOUNTS_ADD}
              fill="solid"
              kind="primary"
              size="small"
            >
              Add New Account
            </Button>
          </AccessRequired>
        }
      />
      <AccountBanner />
      <Table
        headers={TABLE_HEADERS_AWS_ACCOUNTS}
        loading={loading}
        rows={rows}
        showHeader={false}
      />
    </Page>
  );
};

const AccountId = ({ accountId, isOnboarded }) => (
  <div
    className={clsx("text-sm font-semibold text-subdued", {
      "opacity-40": !isOnboarded,
    })}
  >
    {accountId}
  </div>
);

const AccountName = ({ accountName: displayName, accountId, isOnboarded }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [updateAwsAccount] = useUpdateAwsAccount();

  const handleSave = (newDisplayName: string) => {
    updateAwsAccount({ id: accountId, displayName: newDisplayName });
    setIsEditing(false);
  };

  const handleCancel = () => setIsEditing(false);

  return (
    <div className="flex items-center gap-2 py-1" key={accountId}>
      <div
        className={clsx("flex gap-x-2 text-sm font-semibold text-default", {
          "opacity-40": !isOnboarded,
        })}
      >
        {isEditing ? (
          <EditAccountName
            accountName={displayName}
            accountId={accountId}
            onCancel={handleCancel}
            onConfirm={handleSave}
          />
        ) : (
          <>
            {displayName}
            <AccessRequired mutation={MUTATIONS.UPDATE_AWS_ACCOUNT}>
              <PencilIcon
                className="h-2 w-2 cursor-pointer text-action opacity-70 hover:opacity-100"
                onClick={() => setIsEditing(true)}
                aria-label={`Edit name for ${displayName}`}
              />
            </AccessRequired>
          </>
        )}
      </div>
    </div>
  );
};

export const EditAccountName = ({
  accountName,
  onConfirm,
  onCancel,
}: {
  accountName: string;
  accountId: string;
  onConfirm: (updatedName: string) => void;
  onCancel: () => void;
}) => {
  const [displayName, setDisplayName] = useState(accountName);
  const confirmModal = useModalState();

  const isFocusedRef = useRef(false);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setDisplayName(e.target.value);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (accountName === displayName) {
      onCancel();
      return;
    }

    confirmModal.open();
  };

  const handleEscape = () => {
    if (isFocusedRef.current) onCancel();
  };

  const handleConfirm = () => {
    onConfirm(displayName);
    confirmModal.close();
  };

  const handleFocus = () => (isFocusedRef.current = true);
  const handleBlur = () => (isFocusedRef.current = false);

  useEscapeListener(handleEscape);

  return (
    <form className="flex items-center" onSubmit={handleSubmit}>
      <Input
        className="w-[252px]"
        placeholder="Edit Account Name"
        value={displayName}
        pattern="^[a-zA-Z0-9\- ]*$"
        title="Account name must be alphanumeric but can include spaces and hyphens"
        required={true}
        autoFocus={true}
        onChange={handleNameChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      <Link
        aria-label="Confirm edit account name"
        className="ml-2"
        as="button"
        type="submit"
      >
        <CheckIcon className="h-2.5 w-2.5 text-status-good" />
      </Link>
      <Button
        onClick={onCancel}
        aria-label="Cancel edit account name"
        as="button"
        fill="none"
        kind="secondary"
        size="small"
      >
        <XMarkIcon className="h-2.5 w-2.5 text-status-error" />
      </Button>
      <Confirm
        onClose={confirmModal.close}
        open={confirmModal.isOpen}
        actions={[
          <Button key="save-button" kind="primary" onClick={handleConfirm}>
            Confirm
          </Button>,
          <Button key="cancel-button" fill="none" onClick={confirmModal.close}>
            Cancel
          </Button>,
        ]}
      >
        <section className="flex flex-col gap-2">
          <h3 className="text-md font-normal text-subdued">
            Are you sure you want to change the name of this account to{" "}
            <strong>{displayName}</strong>?
          </h3>
          <p className="text-md font-semibold text-subdued">
            This action will update the account's name for all users.
          </p>
        </section>
      </Confirm>
    </form>
  );
};

const TABLE_HEADERS_AWS_ACCOUNTS = [
  { label: "Account ID", accessor: "accountId" },
  { label: "Account Name", accessor: "accountName", width: "100%" },
  { label: " ", accessor: "isPayerAccount" },
  {
    label: "Status",
    accessor: "status",
    width: 300,
  },
  {
    label: "Console",
    accessor: "console",
    width: 150,
  },
];

const isPayerAccount = (
  account: Pick<AwsAccount, "databaseId" | "payerAccountId">
): boolean => {
  const { payerAccountId, databaseId } = account;

  return payerAccountId === databaseId;
};

const isOnboarded = (
  account: Pick<AwsAccount, "awsAccountOnboardingStatus">
): boolean => {
  const { awsAccountOnboardingStatus } = account;

  // AWS accounts onboarded prior to Cloud Gateway release will have an onboarding status
  // of null. We will consider these accounts onboarded until we can finish migrating all
  // legacy AWS accounts to the new awsAccountOnboardingStatus
  return (
    awsAccountOnboardingStatus === AwsAccountOnboardingStatus.Completed ||
    awsAccountOnboardingStatus === null
  );
};

const sortByAccountName = (
  a: Pick<AwsAccount, "name">,
  b: Pick<AwsAccount, "name">
) => {
  const isNumberOnly = (name: string) => /^[0-9]+$/.test(name);

  // Check if either name is only numbers
  const aIsNumberOnly = isNumberOnly(a.name);
  const bIsNumberOnly = isNumberOnly(b.name);

  if (aIsNumberOnly && bIsNumberOnly) {
    return sortNameAsc(a, b); // If both are numbers, regular alphabetical comparison
  } else if (aIsNumberOnly) {
    return 1; // a comes after b
  } else if (bIsNumberOnly) {
    return -1; // b comes after a
  } else {
    // Regular alphabetical comparison
    return sortNameAsc(a, b);
  }
};
