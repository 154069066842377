import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";

export const GET_CASE_DETAILS = gql(/* GraphQL */ `
  query GetCaseDetails($sysId: String!) {
    case(sysId: $sysId) {
      id
      sysId
      number
      shortDescription
      description
      category
      subcategory
      source
      state
      priority
      waitingOn
      openedAt
      openedBy {
        name
        firstName
        lastName
        email
      }
      assignedTo {
        name
        firstName
        lastName
        email
      }
      closeNotes
      activities {
        edges {
          node {
            sysId
            type
            comment
            workNotes
            createdAt
            createdBy {
              name
              firstName
              lastName
              email
            }
            attachmentName
            attachmentSize
            attachmentType
            attachmentHasComment
            attachmentDownloadUrl
            commentAttachments {
              sysId
              attachmentName
              attachmentSize
              attachmentType
              attachmentDownloadUrl
            }
          }
        }
      }
      conversations {
        edges {
          node {
            conversationId
            caseId
            closedAt
            closedBy {
              id
              name
              email
              firstName
              lastName
            }
            createdAt
            createdBy {
              id
              name
              email
              firstName
              lastName
            }
            messages {
              totalCount
            }
            chatParticipants {
              id
              name
              email
              firstName
              lastName
              isStaff
            }
          }
        }
      }
      assignedTo {
        name
        firstName
        lastName
        email
      }
    }
  }
`);

export const useCaseDetails = (sysId: string) => {
  const { loading, error, data, refetch } = useQuery(GET_CASE_DETAILS, {
    variables: { sysId },
  });

  const caseDetails = data?.case;

  const caseActivities =
    caseDetails?.activities?.edges.map((edge) => edge.node) || [];
  const conversations =
    caseDetails?.conversations?.edges.map((edge) => edge.node) || [];

  const allActivities = [...caseActivities, ...conversations];

  return {
    data: {
      caseDetails,
      allActivities,
      caseActivities,
      conversations,
    },
    loading,
    error,
    refetch,
  } as const;
};
