import { RouteObject } from "react-router-dom";

import { Logout } from "@m/login";

import { RegistrationPage } from "@mc/features/Register";

import { defaultRoutes } from "./defaultRoutes";
import { onboardingRoutes } from "./onboardingRoutes";
import { DefaultOutlet, OnboardingOutlet } from "./outlets";
import { reportsRoutes } from "./reportsRoutes";

export const authenticatedRoutes: RouteObject[] = [
  {
    path: "*",
    element: <DefaultOutlet />,
    children: defaultRoutes,
  },
  {
    path: "onboarding/*",
    element: <OnboardingOutlet />,
    children: onboardingRoutes,
  },
  {
    path: "reports/*",
    children: reportsRoutes,
  },

  { path: "register", element: <RegistrationPage /> },
  { path: "logout", element: <Logout /> },
];
